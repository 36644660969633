// auth.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { User } from '../../models/user.model';
import { URL_SERVICES } from '../../config/config';
import { AuthUser } from '../../models/authUser.model';
import { ModalService } from '../modal/modal.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public user: User | null = null;
  private authStatus: boolean | null = null;
  private token: string = '';
  menu: any[] = [];
  private currentUserSubject = new BehaviorSubject<User | null>(null);

  constructor(
    private http: HttpClient,
    private router: Router,
    private modalService: ModalService
  ) {
    this.loadStorage();
  }

  getHeaders(): HttpHeaders {
    this.loadToken();
    return new HttpHeaders({
      Authorization: `Bearer ${this.token}`,
    });
  }

  private updateCurrentUser(user: User | null): void {
    this.currentUserSubject.next(user);
  }

  login(user: AuthUser, rememberMe: boolean = false): Observable<any> {
    let url = URL_SERVICES + '/auth/login-dash';

    // Crear payload con el campo `login` en lugar de `email`
    const payload = {
      login: user.email, // Enviar `login` al backend en lugar de `email`
      password: user.password,
    };

    // Registrar en consola la URL y el payload
    // console.log('Enviando petición de login:', url, payload);

    return this.http
      .post(url, payload, { params: { menuType: 'dashboard' } })
      .pipe(
        map((resp: any) => {
          // console.log('Respuesta del servidor:', resp); // Log de la respuesta en consola

          const token = resp.token;
          const userInfo: User = {
            id: resp.id,
            name: resp.name,
            username: resp.username,
            email: resp.email,
            phone_number: resp.phone_number,
            registeredDate: resp.created_at,
            isActive: resp.isActive,
            roles: resp.roles,
            permissions: resp.permissions,
          };

          this.saveStorage(
            token,
            userInfo,
            resp.menu,
            resp.pendingPayment,
            resp.upcomingBillsWarning
          );
          this.updateCurrentUser(userInfo);
          return { status: true, wizard: resp.wizard };
        }),
        catchError((error) => {
          // console.error('Error en la petición de login:', error); // Log del error en consola
          return throwError(
            () =>
              new Error(
                'Login fallido: ' + (error.error.message || error.message)
              )
          );
        })
      );
  }

  checkStatus(): Observable<boolean> {

    if (this.authStatus !== null) {
      return of(this.authStatus); // Retorna caché si ya se tiene un estado
    }
    let url = `${URL_SERVICES}/auth/check-status-dash`;
    this.token = localStorage.getItem('token') || '';
    return this.http.get<any>(url, { headers: this.getHeaders() }).pipe(
      map((response) => {
        if (response && response.status) {

          this.authStatus = true;
          const userInfo: User = {
            id: response.id,
            name: response.name,
            username: response.username,
            email: response.email,
            phone_number: response.phone_number,
            registeredDate: response.created_at,
            isActive: response.isActive,
            roles: response.roles,
            permissions: response.permissions,
          };

          if (userInfo && response.menu) {
            this.saveStorage(
              response.token,
              userInfo,
              response.menu,
              response.pendingPayment,
              response.upcomingBillsWarning
            );
            this.updateCurrentUser(userInfo);
          }

          return true;
        } else {
          this.authStatus = false;
          this.logout();
          return false;
        }
      }),
      catchError((error) => {
        // console.error('checkStatus failed:', error);
        return throwError(() => new Error('An error occurred'));
      })
    );
  }

  logout(): void {
    this.token = '';
    this.user = null;
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('menu');
    localStorage.removeItem('selectedWarehouse');
    localStorage.removeItem('selectedAccount');
    localStorage.removeItem('pendingPayment');
    localStorage.removeItem('upcomingBillsWarning');

    this.updateCurrentUser(null);
    this.router.navigate(['/login']);
  }

  saveStorage(
    token: string,
    user: User,
    menu: any[],
    pendingPayment: boolean,
    upcomingBillsWarning: boolean
  ): void {
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('menu', JSON.stringify(menu));
    localStorage.setItem('pendingPayment', JSON.stringify(pendingPayment));
    localStorage.setItem(
      'upcomingBillsWarning',
      JSON.stringify(upcomingBillsWarning)
    );
    this.token = token;
    this.user = user;
    this.menu = menu;

    this.updateCurrentUser(user);
  }

  loadStorage(): void {
    this.token = localStorage.getItem('token') || '';
    const userString = localStorage.getItem('user');
    if (userString) {
      this.user = JSON.parse(userString);
      this.updateCurrentUser(this.user);
    }
  }

  loadToken(): void {
    this.token = localStorage.getItem('token') || '';
  }

  public get currentUserValue(): User | null {
    return this.currentUserSubject.value;
  }

  public hasPermission(permission: string): boolean {
    if (!this.user) return false;
    return this.user.permissions.includes(permission);
  }

  private handleError(error: any): Observable<never> {
    let errorMessage = 'Ocurrió un error desconocido';
    if (!navigator.onLine) {
      errorMessage =
        'No hay conexión a internet. Por favor, verifica tu conexión.';
    } else if (error instanceof ErrorEvent) {
      // console.error('Error del lado del cliente:', error.message);
      errorMessage = `Error del lado del cliente: ${error.message}`;
    } else if (error && error.status !== undefined && error.error) {
      // console.error(
      //   `El backend devolvió el código ${error.status}, cuerpo del mensaje: `,
      //   error.error
      // );
      errorMessage = error.error.message || 'Error no especificado';
      if (
        error.status === 0 ||
        error.status === 502 ||
        error.status === 503 ||
        error.status === 504
      ) {
        errorMessage =
          'Servidor temporalmente fuera de linea. Inténtalo más tarde.';
      }
    }
    return throwError(() => new Error(`${errorMessage}`));
  }
}
